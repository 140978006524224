@import "../lib/bootstrap/less/bootstrap.less";
@import "../lib/bootstrap/less/responsive.less";

@import "variables.less";
@import "mixins.less";

html,
body {
    height: 100%;
    font-weight: 300;
}


/* Typography =========================================================== */
h1, h2, h3, h4, h5, h6, legend, thead {
    color: @vmGreen;
    font-weight: normal;
    font-family: @headingsFontFamily;
    line-height: 1.1em;
}

h1 {
    font-size: 3.4em;
    font-weight: 400;
    letter-spacing: -0.1em;
    word-spacing: 0.1em;
}

h1 > small {
    letter-spacing: 0px;
}

h2, h4, h6, thead {
    color: @darkestGrey;
}

a:hover, a:focus {
    text-decoration: none;
}


/* Flat buttons ======================================================= */
.btn {
    background-image: none;
    filter:none;
    font-weight: normal;
    border: none;
    text-shadow: none;
    background-color: @lighterGrey;
}

.btn-primary {background-color: @primaryBlue;}
.btn-info {background-color: @infoBlue;}
.btn-success {background-color: @vmGreen;}
.btn-warning {background-color: @warningOrange;}
.btn-danger {background-color: @dangerRed;}
.btn-inverse {background-color: @darkestGrey;}

/* Mimics form.form-inline; use with div.input-append to spread out our flat
 * buttons and distinguish them.
 *
 * Inside a form, input tags with type "password" trigger the browser's
 * password manager. We don't want this behaviour on the API page, so we use
 * div.input-append instead.
 */
.inline-flat .btn {
  margin: 0 4px;
  border-radius: 4px !important;
}

.inline-flat .btn:first-of-type {
  margin: 0 4px 0 8px;
}

#api-token {
  font-family: @monoFontFamily;
}

/* Custom colors for labels ============================================ */
.label { background-color: @lighterGrey; }
.label-primary {background-color: @primaryBlue;}
.label-info {background-color: @infoBlue;}
.label-success {background-color: @vmGreen;}
.label-warning {background-color: @warningOrange;}
.label-danger {background-color: @dangerRed;}
.label-inverse {background-color: @darkestGrey;}


/* General Sidebar Nav CSS ============================================== */
.nav {word-wrap: break-word;}
.menu-colour {background-color: @lighterGrey;}
.nav li {font-size: 1em;}
.nav-tabs {outline: none;}

#sidebar-row {
    .flex-display;
}

/* row span holding the container holding the sidebar menus */
#sidebar-span {
    vertical-align: top;
    width: 18%;
}

/* container holding the sidebar menus */
#sidebar-container {
    width:100%;
    margin-left: 0%;
    padding: 0em 0em;
    padding-bottom: 1em;
}

/* all ul menus in sidebar, minus top Logo */
.mainmenu {
    margin: 0em;
    padding: 0em;
    background-color: none;
    & > li {
        width: 100%;
        & > a {
            padding: 1.5em 1.2em;
            color: @black;
            opacity:0.65;
            &:hover {
                opacity:1;
            }
        }
    }
    & > .nav-header {
        color: @darkGrey;
        padding-left: 1em;
        font-size: 1em;
        letter-spacing: 0.1em;
    }
}


/* for non-top-level links */
li > a.submenu-link {
    padding-left: 3em;
}

.adminonly {
    background-color: @adminGold;
    color: @adminDarkGold;
    &.control-group {
        padding: 10px;
    }
}

.nav > li.adminonly > a:hover, .nav > li.adminonly > a:focus {
    background-color: @adminGoldHighlight;
}

.nav-text {
    display: inline;
    position: relative;
    top: 2px;
}

.nav-text-padded {
    display: inline;
    padding-left: 0.3em;
    position: relative;
    top: 2px;
}

span.nav-text-arrow {
    margin-top: 7px;
}

/* Login specific ========================================== */

.brand > .logo {
    width: 360px;
    margin-bottom: 20px;
}

.form-signin {
    max-width: 300px;
    padding: 19px 29px 29px;
    margin: 0 auto 20px;
    background-color: @white;
    border: 1px solid @lighterGrey;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin input[type="text"],
.form-signin input[type="password"] {
    font-size: 16px;
    height: auto;
    margin-bottom: 15px;
    padding: 7px 9px;
}

/* Specific menu CSS ========================================== */
/* VMFARMs top logo link */
#logo img {
    width: 100%;
}
#logo a {border: 0;}


/* Dropdown link to switch company accounts */
#company ul {
    margin-left: 100%;
    margin-top: -4.7em;
    padding-top: 15px;
}

#company > .dropdown-menu li{
    letter-spacing: 0.1em;
    padding: 0.5em;
}

#company .dropdown-menu li > a:hover,
#company .dropdown-menu li > a:focus,
#company .dropdown-menu:hover > a {
    background-image: none;
    background-color: @vmGreen;
}

/* User + Logout Icon CSS */
.icon img {
    height: 1.7em;
    width: 1.7em;
}

/* Content block ============================================================*/
#main-row {
    background-color: @white;
    vertical-align: top;
    width: 82%;
}

#status-code {
    margin-top: 96px;
    text-align: center;
}

#status-code pre {
    border: 0px;
    background-color: @white;
    padding: 0;
    margin: 0;
}

#side-extension {
    width: 18%;
    height: 100%;
}

/* Sticky footer ============================================================*/
div.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    /* Negative indent footer by it's height */
    margin: 0 auto -64px;
}

#push {
    background-color: @white;
}

#push,
.footer {
    height: 64px;
}

.footer {
  color: @lightGrey;
  text-align: center;
  padding-left: 18%;

  & > ul {
    margin: 0 auto;
    & > li {
      display: inline;
      margin: 0px 0.5em;
      &:first-of-type {
        margin: 0 0.5em 0 0;
      }
      &:last-of-type {
        margin: 0 0 0 0.5em;
      }
    }
  }

  & a {
    color: @lightGrey;
    &:hover, &:focus {
      color: @darkGrey;
    }
  }

  & a.logo {
    background-color: transparent;
    background-image: url('/static/img/logos/logomark-inverted.svg');
    background-repeat: none;
    background-size: 64px;
    display: inline-block !important;
    filter: grayscale(100%) brightness(90%);
    height: 21px;
    margin-bottom: -0.5ex;
    width: 64px;
    &:hover, &:focus {
      filter: grayscale(100%) brightness(80%);
    }
  }
}

/* Carets / Triangle arrows =================================================*/
.arrow-down {
    width: 0;
    height: 0;
    display: inline;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid @lightGrey;
    position: absolute;
    margin-top: 7px;
    margin-left: 5px;
    filter: alpha(opacity=75);
    opacity:0.75;
}

.arrow-down:hover, .arrow-down:focus {
    filter: alpha(opacity=100);
    opacity:1;
}

.arrow-up {
    width: 0;
    height: 0;
    display: inline;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid @lightGrey;
    position: absolute;
    margin-top: 7px;
    margin-left: 5px;
    filter: alpha(opacity=75);
    opacity:0.75;
}

.arrow-right {
    width: 0;
    height: 0;
    display: inline;
    border-left: 5px solid @lightGrey;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
    filter: alpha(opacity=75);
    opacity:0.75;
}

.arrow-left {
    width: 0;
    height: 0;
    display: inline;
    border-right: 5px solid @lightGrey;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    position: absolute;
    margin-top: 5px;
    margin-left: -10px;
    filter: alpha(opacity=75);
    opacity:0.75;
}


.nav-links {
    font-size: 10pt;
    text-decoration: none;
    color: @black;
}

.nav-links:hover,
.nav-links:focus {
    color: green;
}

/* Deploys ====================================================== */
#deploy-form {
    margin: 0;

    input, select {
        margin-right: 5px;
    }
}

table.deploy-list {
    &.deploy-dashboard {
        width: 100%;

        /* This is to a get semi-consistent layout for all tables in dashboard view */
        .environment {
            width: 25%;
        }

        .group {
            width: 75px;
        }
    }

    .build-group .label {
        display: block;
        text-align: center;
    }

    td {
        vertical-align: middle;
    }

    tr.live {
        font-weight: bold;
    }

    td.success {
        background-color: #f1f8e9;
    }

    td.error {
        background-color: #ffcdd2;
    }

    td.info {
        background-color: #fffde7;
    }

    .time-tooltip {
        border-bottom: 1px dotted grey;
    }
}

article.app {
    margin: 30px 0;
    border-bottom: 1px solid #eeeeee;

    table {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

/* Loading GIF ====================================================== */
.loading {
    background: url('/static/img/loading-spinner.gif') no-repeat center center;
    padding-top: 5em;
}

#selector-loading {
    height: 100px;
    width: 100px;
    background-size: 100% auto;
    margin-left: auto;
    margin-right: auto;
    display: none;
}

.linkable{
    cursor:pointer;
}

/* For select2 */
.control-group.error [class^="select2-choice"] {
    border-color: @dangerRed;
}

.left-quote {
    margin-left: -10px;
}

table.table-review-apply > tbody > tr > td > code {
    background-color: transparent;
    border: none;
    color: @infoBlue;
}

.table-review-apply p {
    margin-bottom: 0px;
}

table.table-review-apply tr.success code {
    color: @vmGreen;
}

table.table-review-apply tr.error code {
    color: @dangerRed;
}

table.table-review-apply tr.info code {
    color: @infoBlue;
}

.review-header {
    background-color: @lightestGrey;
}

/* widgets */

.input-tall {
    height: 42px;
}

.widget-tabular-select-multiple > div.controls > div.help-block > p{
    display: none;
}

.widget-tabular-select-multiple > label {
    -webkit-margin-before: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    text-rendering: optimizelegibility;
    font-size: 24.5px;
    color: @vmGreen;
    font-weight: normal;
    font-family: @headingsFontFamily;
    line-height: 1.1em;
}

.markdown-lead {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 200;
    line-height: 30px;
}

.markdown-lead > p > code,
.bs-callout > p > code,
.bs-callout > h4 > p > code {
    font-size: 18px;
}

label.checkbox > div.help-block {
    font-weight: 300;
}

table.inline-m2m tbody td p,
table.inline-o2m tbody td p {
    margin: 4px 0 0;
}

div.control-group > div.controls.field-key > #id_key {
    width: 700px;
    height: 200px;
}

div.control-group > div.controls.field-owner > #id_owner {
    width: 700px;
}

.select2 {
    width: 250px;
}

/* Common styles for all types */
.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}
.bs-callout h4,
.bs-callout h3 {
    margin-top: 0;
    margin-bottom: 5px;
}
.bs-callout p:last-child,
.bs-callout .markdown-lead,
.bs-callout ul:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}

/* Tighten up space between multiple callouts */
.bs-callout + .bs-callout {
    margin-top: -5px;
}

/* Variations */
.bs-callout-danger {
    border-left-color: @dangerRed;
}
.bs-callout-danger h4,
.bs-callout-danger h3 {
    color: @dangerRed;
}
.bs-callout-warning {
    border-left-color: @warningOrange;
}
.bs-callout-warning h4,
.bs-callout-warning h3 {
    color: @warningOrange;
}
.bs-callout-info {
    border-left-color: @infoBlue;
}
.bs-callout-info h4,
.bs-callout-info h3 {
    color: @infoBlue;
}
.bs-callout-success {
    border-left-color: @vmGreen;
}
.bs-callout-success h4,
.bs-callout-success h3 {
    color: @vmGreen;
}

.help-block {
    font-weight: bold;
}

#top-span a {
    display: inline-block;
}

#top-span .logo {
    width: 330px;
    height: auto;
}

/* Build log */
#log {
    font-family: monospace;
    overflow: auto;
    max-height: 600px;
    padding: 0.5em;
    border-radius: .25em;
    line-height: 0;
    counter-reset: line;
    white-space: nowrap;

    span {
        display: block;
        line-height: 1.25rem;

        &:before {
            counter-increment: line;
            content: counter(line);
            display: inline-block;
            border-right: 1px solid #ddd;
            padding: 0 .5em;
            margin-right: .5em;
            color: #888;
            width: 2.5em; /* Fixed width */
        }
    }
}


/* Secret message */
#secret-message {
    font-size: 120%;
    line-height: 2;
    padding: 10;
}

.secret-message-textarea {
    font-size: 200%;
    padding: 6px 10px 6px 10px;
    line-height: 1.2;
}


/* Misc */
.popover {
    max-width: 400px;
}

.modal {
    width: 840px;
    margin-left: -420px;
}

.page-header > .breadcrumb {
    margin-top: 20px;
    margin-bottom: 0;
}


/* Sorttable ======================================================= */
/* http://www.kryogenix.org/code/browser/sorttable/#customkeys */
/* Adds Up/Down arrows to sortable table columns, providing a UI hint. */
table.sortable th:not(.sorttable_sorted):not(.sorttable_sorted_reverse):not(.sorttable_nosort):after {
    content: " \25B4\25BE"
}

