// Custom colors
@adminDarkGold:         #c09853;
@adminGold:             #fcf8e3;
@adminGoldHighlight:    #faf2cc;
@brightGreen:           #00F800;
@brownBlack:            #221f1f;
@dangerRed:             #da4f49;
@darkGrey:              #555555;
@darkestGrey:           #363636;
@infoBlue:              #49afcd;
@lightGrey:             #999999;
@lighterGrey:           #ededed;
@lightestGrey:          #f9f9f9;
@mediumGrey:            #7f7f7f;
@primaryBlue:           #006dcc;
@vmGreen:               #6cc644;
@warningOrange:         #faa732;

// Typography
// -------------------------
@baseFontFamily:        "Muli", sans-serif;
@headingsFontFamily:    "Noto Sans", sans-serif;
@headingsFontWeight:    normal;
@headingsColor:         @vmGreen;

// Sprite icons path
// -------------------------
@iconSpritePath:          "/static/lib/bootstrap/img/glyphicons-halflings.png";
@iconWhiteSpritePath:     "/static/lib/bootstrap/img/glyphicons-halflings-white.png";
